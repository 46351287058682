import React from "react"

import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import ContentRestaurantBar from "../components/content-restaurant-bar"
import data from "../data/restaurant-bar.yaml"

const RestaurantBarPage = (props) => {
  const {
    pageContext: { lang },
  } = props
  return (
    <>
      <SEO title={data.title[lang]} />
      <PageHeader
        headerImage={data.headerImage}
        headerAlt={data.headerAlt}
        title={data.title[lang]}
        subTitle={data.subtitle[lang]}
        info={data.info}
      />
      <ContentRestaurantBar
        contentHeader={data.content.header[lang]}
        content={data.content.text[lang]}
        contentImage={data.content.image}
      />
    </>
  )
}

export default RestaurantBarPage
