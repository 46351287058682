import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import Markdown from "markdown-to-jsx"

import theme from "./theme"

const Section = styled.section`
  padding: 2rem 0;
  ${theme.breakpoint.md} {
    padding: 3rem 0;
  }
`
const Image = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  box-shadow: ${theme.shadow.main};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${theme.breakpoint.mdOnly} {
    width: 100%;
    height: 500px;
  }
  ${theme.breakpoint.lg} {
    width: 400px;
    max-width: 100%;
    height: 500px;
  }
`
export default function ContentReataurantBar(props) {
  return (
    <>
      <Section>
        <Container>
          <Row>
            <Col md="5" className="ml-auto d-flex flex-column justify-content-center">
              <h2 className="h3 text-info">{props.contentHeader}</h2>
              <Markdown>{props.content}</Markdown>
            </Col>
            <Col md="6" lg="5" className="mr-auto">
            <Image>
                <img src={`/images/${props.contentImage}`} alt="" />
              </Image>
            </Col>
          </Row>
        </Container>
      </Section>

    </>
  )
}
